//
// figure
//

figure {
  margin: 0;
  background-size: cover;
  background-position: center;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .plyr {
    @include translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 135%;
    max-width: 135%;

    > .plyr__control {
      display: none !important;
    }
  }

  .map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @include media-breakpoint-down(lg) {
    .plyr {
      width: 240%;
      max-width: 240%;
    }
  }

  @include media-breakpoint-down(md) {
    .plyr {
      width: 385%;
      max-width: 385%;
    }
  }

  &[class*="-overlay"] {
    &::after {
      transition: opacity 0.2s;
      position: absolute;
      opacity: 0.4;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background: $black;
    }
  }

  &[class*="-dimm"] {
    opacity: 0.3;
  }

  &[class*="-gradient-horizontal"] {
    mask-image: -webkit-gradient(
      linear,
      right bottom,
      left bottom,
      from(rgba(0, 0, 0, 0.8)),
      to(rgba(0, 0, 0, 0))
    );
  }

  &[class*="-gradient-vertical"] {
    mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 25%,
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 0) 100%
    );

    &::before {
      @include transition;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      z-index: 24;
      background-color: rgba($black, 0.4);
    }
  }
}

.hover-gradient {
  @include transition;
  background: $black;

  .background {
    mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0.4))
    );
  }
}

// media
.media {
  width: 100%;
  margin: 0;
  overflow: hidden;

  span,
  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  &[class*="-background"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &[class*="-video"] {
    span {
      transition: transform 0.3s ease-in-out;
      transform: scale(1);
    }

    &::after {
      transform: scale(1.1);
      transition: all 0.2s;
      position: absolute;
      font-family: bootstrap-icons;
      content: "\f4f4";
      top: 50%;
      left: 50%;
      width: rem(100);
      height: rem(100);
      margin-top: rem(-50);
      margin-left: rem(-50);
      line-height: rem(100);
      border-radius: rem(100);
      background-color: $black;
      color: $white;
      opacity: 1;
      text-align: center;
      font-size: rem(32);
      cursor: pointer;
    }
    &:hover {
      &::after {
        transform: scale(1);
      }

      span {
        transform: scale(1.05);
      }
    }
  }

  &[class*="-image"] {
    span {
      transition: transform 0.3s ease-in-out;
      transform: scale(1);
    }

    &:hover {
      span {
        transform: scale(1.05);
      }
    }
  }
}

.background-parallax {
  width: auto;
  height: rem(120%);
  margin-top: -20%;
}

// decoration
.frame {
  position: relative;
  padding: rem(16);

  > * {
    transition: transform 0.2s;
    transform: translate(rem(-16), rem(-16));
    position: relative;
    z-index: 4;
  }

  > span {
    @include border-radius($border-radius);
    transition: transform 0.2s;
    transform: translate(rem(16), rem(16));
    position: absolute;
    top: rem(16);
    right: rem(16);
    bottom: rem(16);
    left: rem(16);
    display: block;
    border: $border-width solid $border-color;
    z-index: 0;
  }

  &[data-aos] {
    > *,
    > span {
      transform: translate(0, 0);
    }

    &.aos-animate {
      > * {
        transform: translate(rem(-16), rem(-16));
      }

      > span {
        transform: translate(rem(16), rem(16));
      }
    }
  }
}

.img-stack {
  position: relative;

  img {
    position: relative;
    z-index: 8;
    margin-top: rem(-10);
  }

  img + img {
    position: absolute;
    z-index: 4;
  }
}

.browser {
  position: relative;
  background: $white;
  padding-top: rem(56);
  overflow: hidden;

  > span {
    display: flex;
    position: absolute;
    top: rem(28);
    margin-top: rem(-6);
    left: rem(32);
    width: rem(12);
    height: rem(12);
    background: $red;
    border-radius: 50%;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: rem(24);
      display: block;
      width: rem(12);
      height: rem(12);
      background: $yellow;
      border-radius: 50%;
    }

    &::after {
      left: rem(48);
      background: $green;
    }
  }

  img {
    vertical-align: top;
  }
}
