//
// your custom scss goes here
//
$colorTop : #ffffffe0;
$colorBottom: #fbfbfb00;

.home-hero {
  background-image: url('https://www.dimdata.com/assets/images/2025q1/hero2025jhc.jpg');
  cursor: unset !important;
}

.no-text-transform {
  text-transform: none !important;
}

.btn-m150 {
  min-width: 150px;
}

.bg-gr1{
  background: rgb(9, 141, 69);
    background: linear-gradient(159deg, rgba(9, 141, 69, 1) 0%, rgba(146, 203, 112, 1) 100%);
}

.bg-gr2{
  background: linear-gradient(
  135deg,
  rgba(255, 240, 240, 0.8) 0%,
  rgba(255, 220, 255, 0.8) 25%,
  rgba(220, 220, 255, 0.8) 50%,
  rgba(200, 220, 255, 0.8) 100%
);
}

.bg-gr3{
  background: linear-gradient(159deg, #4b32d8 0%, #c8c97a 100%);
}

.bg-gr4{
  background: linear-gradient(159deg, #D8328A 0%, #9EC97A 100%);
}

.bg-enterprise1 {
  background: linear-gradient(135deg, rgba(220, 240, 255, 0.8) 0%, rgba(200, 220, 255, 0.8) 25%, rgba(180, 200, 220, 0.8) 50%, rgba(160, 180, 200, 0.8) 100%);
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
}


.hand {
  cursor: pointer;
}

.bg-opaque-green {
  background-color: rgba(29,75,64,0.1) !important;
}

.tb-header-stick {
  background: white; 
  position: sticky;
  top: 0;
  z-index: 1040; //Top bar is 1040
  border-bottom: 1px solid #dadce0 !important;
}

.tb-header-cx {
  border-left: none;
  border-right: 1px solid #dadce0 !important;
  background-color: #f8f9fa !important;
}

@include media-breakpoint-up(xl) {
  .hero-block2 {
    min-height: unset;
  }

    
.material-symbols-outlined.icon-size-var {
  font-size: 72px;
}
}

@include media-breakpoint-down(xl) {
  .hero-block2 {
    min-height: 195px;
  }

  .home-hero {
    background-image: url('https://www.dimdata.com/assets/images/2025q1/hero2025jhc.jpg');
    cursor: unset !important;
  }
    
.material-symbols-outlined.icon-size-var {
  font-size: 72px;
}

}

@include media-breakpoint-down(lg) {
  .hero-block2 {
    min-height: 118px;
  }

  .home-hero {
    background-image: url('https://www.dimdata.com/assets/images/2025q1/hero2025jhc.jpg');
    cursor: unset !important;
  }

  
.material-symbols-outlined.icon-size-var {
  font-size: 64px;
}

}

@include media-breakpoint-down(md) {
  .hero-block2 {
    min-height: 100px;
  }

  
.material-symbols-outlined.icon-size-var {
  font-size: 48px;
}

}

.material-symbols-outlined.icon-size-72 {
  font-size: 72px;
}

.text-bgreen {
  background: linear-gradient(90deg, #0078d4, #00a36c); /* Adjust colors as needed */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-bgreen-hv:hover {
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  filter: brightness(1.2); /* Slight brightness effect */
  transition: all 0.3s ease;
}

.media-x span {
  background-size: cover;
}

.mh400 {
  max-height: 400px;
}

.text-primary-hover:hover {
  color: $primary;
}

.cardwhover:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3) !important; /* Add a deeper shadow */
}
