//
// sizing
//
//

$ratio: ();
$ratio: map-merge(
  (
    "16-9": 56.25%,
    "16-10": 62.5%,
    "4-3": 75%,
    "3-4": 133.3333%,
    "2-1": 50%,
    "1-2": 200%,
    "1-1": 100%,
  ),
  $ratio
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $size in $ratio {
      .equal#{$infix}-#{$name} {
        position: relative;
        display: block;
        overflow: hidden;
        height: auto !important;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);

        &::before {
          display: block;
          content: "";
          width: 100%;
          padding-top: $size;
        }

        > * {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}
