//
// list groups
//

.list-group-flush {
  .list-group-item {
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;
  }
}

.list-group-minimal {
  .list-group-item {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.list-group-separated {
  .list-group-item {
    border: 0;
    padding-left: 0;
    padding-right: 0;

    &:not(:first-child) {
      border-top: $border-width solid $border-color;
    }
  }
}

.list-group-numbered {
  .list-group-item {
    padding-left: rem(32);

    &::before {
      position: absolute;
      left: rem(8);
      top: 50%;
      margin-top: -0.5em;
      line-height: 1em;
      font-size: $font-size-lg;
    }
  }

  &.list-group-separated,
  &.list-group-minimal {
    .list-group-item::before {
      left: 0;
    }
  }
}
