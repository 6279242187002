// fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// bootstrap & variables
@import "~bootstrap/scss/functions";
@import "variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "user";
@import "~bootstrap/scss/bootstrap";
@import "utilities";

// components
@import "components/_index";
