//
// code
//

pre[class*="language-"],
code[class*="language-"] {
  padding: 0;
  background: transparent;
  font-family: $font-family-monospace;
  font-size: $font-size-sm;
  text-shadow: none;
  max-height: 50vh;
}

// toolbar
div.code-toolbar {
  position: relative;

  &:hover {
    .toolbar {
      opacity: 1;
    }
  }

  > .toolbar {
    @include transition;
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;

    .toolbar-item {
      display: block;
    }

    button {
      @include transition;
      background: $white;
      color: $black;
      display: block;
      padding: rem(4) rem(12);
      font-size: $font-size-sm;
      font-family: $headings-font-family;
      text-transform: uppercase;
      border: 0;

      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }
}

kbd {
  margin: 0 rem(4);
  padding: rem(4) rem(8);
  background: $black;
  color: $white;
  letter-spacing: 0.1em;
}

.inverted {
  pre[class*="language-"],
  code[class*="language-"] {
    color: lighten($primary, 10%);
  }

  // syntax
  .token {
    color: rgba($white, 0.5);

    &.selector,
    &.punctuation {
      color: $white;
    }
  }
}
